import dayjs from 'dayjs';
import type { ContentAuthor } from '~/shared/types/content.type';
import type { AppLocale } from '~/data/domain/app.locale';
import { USER_AVATAR_STUMP_PATH } from '~/shared/constants/static-images-paths.constant';
import type { BackendNewsModel, BackendNewsModelBase } from '~/features/news/services/news.type';

export interface NewsModelBase {
  id: number;
  title: string;
  slug: string;
  shortDescription: string;
  image: string | null;
  author: ContentAuthor;
  publishedAt: string;
  publishedAtFormatted?: string;
}

export interface NewsModel extends NewsModelBase {
  content: string | null;
  likes: number;
  pageviews: number;
  isLiked: boolean;
}

export function createNewsModelBase(raw: BackendNewsModelBase, locale: AppLocale): NewsModelBase {
  return {
    id: raw.id,
    title: raw.title,
    shortDescription: raw.short_description,
    image: raw.image,
    publishedAt: raw.published_at,
    publishedAtFormatted: dayjs(raw.published_at).locale(locale).fromNow(),
    author: {
      image: raw.author_avatar ?? USER_AVATAR_STUMP_PATH,
      name: raw.author_name,
    },
    slug: raw.slug,
  };
}

export function createNewsModel(raw: BackendNewsModel, locale: AppLocale): NewsModel {
  const baseModel = createNewsModelBase(raw, locale);

  return {
    ...baseModel,
    content: raw.content,
    likes: raw.likes,
    pageviews: raw.pageviews,
    isLiked: raw.isLiked,
  };
}
