import type { CustomFetch } from '~/shared/types/custom-fetch.type';
import { createNewsModelBase, type NewsModelBase } from '~/features/news/models/news.model';
import type { BackendNewsModel, BackendNewsModelBase } from '~/features/news/services/news.type';
import type { QueryFunctionContext } from '@tanstack/vue-query';
import type { newsKeys } from '~/features/news/queries/news.query';
import type { BackendItemsListWithPaginationResponse } from '~/data/services/types/backend-items-list.type';
import type { ItemsListWithInfiniteLoading } from '~/shared/types/items-list.type';

export const NewsService = (fetch: CustomFetch) => ({
  async getNewsMainPage({
    queryKey: [{ locale }],
  }: QueryFunctionContext<ReturnType<(typeof newsKeys)['mainPage']>>): Promise<NewsModelBase[]> {
    const news: BackendNewsModelBase[] = await fetch('api/main-page/top-news', {
      credentials: 'include',
    });

    return news.map((item) => createNewsModelBase(item, locale));
  },
  async getNewsList(
    [{ locale }]: ReturnType<(typeof newsKeys)['list']>,
    page: number,
  ): Promise<ItemsListWithInfiniteLoading<NewsModelBase>> {
    const res: BackendItemsListWithPaginationResponse<BackendNewsModelBase> = await fetch(
      'api/list/news',
      {
        credentials: 'include',
        params: {
          page,
        },
      },
    );

    const data = res.data.map((item: BackendNewsModelBase) => createNewsModelBase(item, locale));
    const nextCursor = res.current_page < res.last_page ? res.current_page + 1 : undefined;

    return {
      data,
      nextCursor,
    };
  },
  async getNewsItemBySlug(slug: string): Promise<BackendNewsModel> {
    return await fetch(`api/page/news/${slug}`, {
      credentials: 'include',
      localError: true,
    });
  },
  async getOtherNewsList(slug: string): Promise<BackendNewsModel[]> {
    return await fetch(`api/page/news/${slug}/other`, {
      credentials: 'include',
    });
  },
  async like(id: number, like = true) {
    const apiPath = like ? `api/v1/like/news/${id}` : `api/v1/unlike/news/${id}`;

    return await fetch(apiPath, {
      localError: true,
      method: 'POST',
    });
  },
  async getNewsDapp({
    queryKey: [{ slug, locale }],
  }: QueryFunctionContext<ReturnType<(typeof newsKeys)['dappList']>>): Promise<NewsModelBase[]> {
    const news: BackendNewsModel[] = await fetch(`api/page/dapp/${slug}/recommend-news`, {
      credentials: 'include',
    });

    return news.map((item) => createNewsModelBase(item, locale));
  },
});
